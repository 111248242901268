import Clerk from '@clerk/clerk-js'

window.Webflow ||= []
window.Webflow.push(async () => {
  const path = window.location.pathname
  const clerk = new Clerk('pk_test_bGliZXJhbC1nb2xkZmlzaC04Ni5jbGVyay5hY2NvdW50cy5kZXYk')
  await clerk.load()
  const connect = document.querySelector('#connect') as HTMLDivElement
  const isConnect = clerk.session !== undefined && clerk.session !== null

  connect.addEventListener('click', () => {
    if (isConnect) {
      clerk.signOut()
      connect.innerHTML = 'Connexion'
      window.location.reload()
    } else window.location.href = '/login'
  })

  if (path === '/login') {
    const signIn: HTMLDivElement | null = document.querySelector('#signIn')
    if (!signIn) return
    clerk.mountSignIn(signIn, {
      appearance: {
        elements: {
          card: {
            backgroundColor: '#111',
            color: '#ffffff',
          },
          socialButtonsIconButton: {
            backgroundColor: '#ecede9',
            '&:hover': {
              backgroundColor: '#ecede9',
            },
          },
          header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          headerTitle: {
            color: '#ffffff',
          },
          headerSubtitle: {
            color: '#888',
          },
          dividerLine: {
            backgroundColor: '#888',
          },
          dividerText: {
            color: '#888',
          },
          formFieldLabel: {
            color: '#888',
          },
          formFieldInput: {
            backgroundColor: '#ecede9',
          },
          footerActionText: {
            color: '#888',
          },
          footerActionLink: {
            color: '#ffc17b',
            '&:hover': {
              color: '#ffc17b',
            },
          },
          formButtonPrimary: {
            backgroundColor: '#ffc17b',
            color: '#060809',
            '&:hover': {
              backgroundColor: '#ffc17b',
            },
          },
        },
        layout: {socialButtonsVariant: 'iconButton'},
      },
    })
  }

  if (clerk.session !== undefined && clerk.session !== null) {
    connect.innerHTML = 'Déconnexion'
  }
})
